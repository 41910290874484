import axios from 'axios';

export const connectNeurosity = async (userId: string, token: string) => {
  try {
    const axiosObj = await axios({
      method: 'POST',
      url: `/v2/users/${userId}/connectedApps/neurosity`,
      data: {
        token,
      },
    });
    return {
      apps: Object.entries(axiosObj.data.connectedApps),
      appsNames: Object.keys(axiosObj.data.connectedApps),
      error: null,
    };
  } catch (e) {
    console.error(e);
    return {
      apps: null,
      appsNames: null,
      errorMessage: e.message,
    };
  }
};

export const getNeurosityOAuthToken = async (userId: string) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `/v2/users/${userId}/connectedApps/neurosity/oauthToken`,
    });

    return { oAuthToken: response.data, error: null };
  } catch (e) {
    console.error(e);
    return { oAuthToken: null, error: e.message };
  }
};

export const getNeurosityOAuthURL = async () => {
  const currentUrl = window.location.href;
  try {
    const axiosObj = await axios({
      method: 'GET',
      url: `/v2/users/connectedApps/neurosity/oauthURL?redirectUri=${currentUrl}`,
    });

    const oAuthStartUrl: string = axiosObj.data.url;

    return { oAuthUrl: oAuthStartUrl, error: null };
  } catch (e) {
    console.error(e);
    return { oAuthUrl: null, error: e.message };
  }
};

export const migrateDevicesNeurosityOAuth = async (userId: string, token: string) => {
  try {
    await axios({
      method: 'PUT',
      url: `/v2/users/${userId}/connectedApps/neurosity/migrateDevices`,
      data: {
        oauthToken: token,
      },
    });

    return {
      migrated: true,
      error: null,
    };
  } catch (e) {
    console.error(e);
    return { migrated: false, error: e.message };
  }
};

export const disconnectNeurosity = async userId => {
  try {
    const axiosObjNeurosityToken = await axios({
      method: 'GET',
      url: `/v2/users/${userId}/connectedApps/neurosity/oauthToken`,
    });

    const token = axiosObjNeurosityToken.data;

    const axiosObj = await axios({
      method: 'DELETE',
      url: `/v2/users/${userId}/connectedApps/neurosity`,
      data: {
        token,
      },
    });

    return {
      apps: Object.entries(axiosObj.data.connectedApps),
      appsNames: Object.keys(axiosObj.data.connectedApps),
      error: null,
    };
  } catch (e) {
    console.error(e);
    return {
      apps: null,
      appsNames: null,
      error: e.message,
    };
  }
};

export const getConnectedApps = async userId => {
  try {
    const axiosObj = await axios({
      method: 'GET',
      url: `/v2/users/${userId}/connectedApps`,
    });

    return {
      apps: Object.entries(axiosObj.data.connectedApps),
      appsNames: Object.keys(axiosObj.data.connectedApps),
      error: null,
    };
  } catch (e) {
    console.error(e);
    return {
      apps: null,
      appsNames: null,
      error: e.message,
    };
  }
};

export const transferDeviceOwnership = async (userId, recipientId, deviceId) => {
  try {
    const axiosObj = await axios({
      method: 'POST',
      url: `/v2/users/connectedApps/neurosity/transferDevice`,
      data: {
        userId,
        recipientId,
        deviceId,
      },
    });
    console.log(axiosObj);

    return true;
  } catch (e) {
    console.error(e.message);
    return false;
  }
};
