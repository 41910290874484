import React, { useState, createContext, useContext } from 'react';
import { PaymentMethod } from 'src/types/payment';

type OnBoard = {
  state: string,
}

interface GlobalContextInterface {
  setPaymentMethods: (a: PaymentMethod[]) => void;
  userPaymentMethods: PaymentMethod[];
  onboardGlobal: OnBoard
  setOnboardGlobal: React.Dispatch<React.SetStateAction<OnBoard>>;
}

const GlobalContext = createContext<GlobalContextInterface>({
  setPaymentMethods() {
    throw new Error('You need to wrap your component in <GlobalContext>');
  },
  userPaymentMethods: [],
  setOnboardGlobal() {
    throw new Error('You need to wrap your component in <GlobalContext>');
  },
  onboardGlobal: {
    state: ""
  }
});

const GlobalContextProvider = ({ children }) => {
  const [userPaymentMethods, setPaymentMethods] = useState<PaymentMethod[]>();
  const [onboardGlobal, setOnboardGlobal] = useState<OnBoard>({
    state: ""
  });

  return (
    <GlobalContext.Provider
      value={{
        setPaymentMethods,
        userPaymentMethods,
        setOnboardGlobal,
        onboardGlobal
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;

export const useGlobal = (): GlobalContextInterface => useContext(GlobalContext);
