import React from 'react';
import { Prompt, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import UpdateVersionBanner from 'src/components/UpdateVersionBanner';
import useSettings from 'src/hooks/useSettings';
import TimerProvider from 'src/contexts/TimerContext';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import GlobalContextProvider from './contexts/GlobalContext';
import { RootState, useSelector } from './store';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const App = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  const { protocolIsEditing } = useSelector((state: RootState) => state.programs);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <TimerProvider>
          <GlobalContextProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider dense maxSnack={3}>
                <Router history={history}>
                  <Prompt
                    when={protocolIsEditing}
                    message={() =>
                      `Are you sure you want to go to leave this page? Any unsaved changes will be lost.`
                    }
                  />
                  <Elements stripe={stripePromise}>
                    <AuthProvider>
                      <GlobalStyles />
                      <ScrollReset />
                      <GoogleAnalytics />
                      <UpdateVersionBanner />
                      {renderRoutes(routes)}
                    </AuthProvider>
                  </Elements>
                </Router>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </GlobalContextProvider>
        </TimerProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
