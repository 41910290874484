import React from 'react';
import { IconButton, SvgIcon, Tooltip, Link } from '@material-ui/core';
import { FiHelpCircle } from 'react-icons/fi';

const Help = () => (
  <Link href="https://community.divergenceneuro.com/" target="_blank" rel="noreferrer noopener">
    <Tooltip title="Help">
      <IconButton>
        <SvgIcon>
          <FiHelpCircle color="white" />
        </SvgIcon>
      </IconButton>
    </Tooltip>
  </Link>
);

export default Help;
