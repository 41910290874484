import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from 'src/slices/authentication';
import { reducer as clientsReducer } from 'src/slices/clients';
import { reducer as assessmentsReducer } from 'src/slices/assessments';
import { reducer as programReducer } from 'src/slices/programs';
import { reducer as deviceReducer } from 'src/slices/devices';
import { reducer as sessionReducer } from 'src/slices/sessions';
import { reducer as feedbackResponseReducer } from 'src/slices/feedbackResponses';
import { reducer as questionaresReducer } from 'src/slices/questionnaires';
import { reducer as productReducer } from 'src/slices/products';

const rootReducer = combineReducers({
  authentication: authReducer,
  clients: clientsReducer,
  assessments: assessmentsReducer,
  programs: programReducer,
  devices: deviceReducer,
  sessions: sessionReducer,
  feedbackResponses: feedbackResponseReducer,
  questionaires: questionaresReducer,
  products: productReducer,
});

export default rootReducer;
