import React, { useState } from 'react';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { Theme } from 'src/theme';
import CloseIcon from '@material-ui/icons/Close';
import { dismissNotifications } from 'src/requests/notifications';

const NotificationBanner = ({ data, id }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleClick = () => {
    setIsOpen(!isOpen);
    dismissNotifications(id, data.notificationId);
  };

  return !isOpen ? null : (
    <Box className={classes.container}>
      <Typography dangerouslySetInnerHTML={{ __html: data.content }} />
      <Button onClick={handleClick} className={classes.button}>
        <CloseIcon />
      </Button>
    </Box>
  );
};

export default NotificationBanner;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    width: '100%',
    marginTop: 64,
    background: '#773dff',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    justifyContent: 'space-between',
    zIndex: theme.zIndex.drawer + 100,
  },
  button: {
    background: '#fff',
    color: '#773cfe',
    marginLeft: 20,
    '&:hover': {
      background: '#fff',
    },
  },
}));
