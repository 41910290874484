import { Snackbar, makeStyles } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useLocation } from 'react-router-dom';

import { useEffect, useRef, useState } from 'react';

const useStyles = makeStyles(() => ({
  snackBar: {
    background: '#FC8AC8',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  alert: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'inherit',
    fontSize: 'large',
    color: 'white',
    textAlign: 'center',
  },
}));

const UpdateVersionBanner = () => {
  const classes = useStyles();
  const currRouterLocation = useLocation();
  const [newVersionAvailable, setNewVersionAvailable] = useState<boolean>(false);
  const [locationPath, setLocationPath] = useState<string>(currRouterLocation.pathname);
  const serviceWorkerChannel = useRef<BroadcastChannel>();

  useEffect(() => {
    serviceWorkerChannel.current = new BroadcastChannel('DVG_BROADCAST_SW');
    serviceWorkerChannel.current.onmessage = event => {
      if (event?.data?.type === 'UPDATE_FOUND') {
        setNewVersionAvailable(true);
      }
      if (event?.data.type === 'INITIATE_REFRESH') {
        window.location.reload();
      }
    };

    return () => {
      serviceWorkerChannel.current.close();
    };
  }, []);

  const checkSWUpdate = async () => {
    try {
      //if new service worker is available, trigger website update flow
      const [serviceWorkerRegistration] = await navigator.serviceWorker.getRegistrations();
      if (serviceWorkerRegistration) await serviceWorkerRegistration.update();
    } catch (e) {
      console.error(e);
    }
  };

  if (currRouterLocation.pathname !== locationPath) {
    setLocationPath(currRouterLocation.pathname);
    checkSWUpdate();
  }

  const handleRefresh = () => {
    setNewVersionAvailable(false);
    serviceWorkerChannel.current.postMessage({ type: 'INITIATE_REFRESH' });
    window.location.reload();
  };

  return (
    <Snackbar
      open={newVersionAvailable}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClick={handleRefresh}
      className={classes.snackBar}
    >
      <MuiAlert
        elevation={6}
        icon={<RefreshIcon htmlColor="white" />}
        severity="info"
        className={classes.alert}
      >
        A new version of this web app is available. Click here to refresh and get the latest
        version.
      </MuiAlert>
    </Snackbar>
  );
};

export default UpdateVersionBanner;
