import { Product, SortedSubscriptions } from 'src/types/payment';

/**
 * Takes total list of products and returns
 * (a) subscription tiers
 * (b) recurring fees
 * (c) one time fees
 */
const sortProducts = (products: Product[]) => {
  const subscriptionTypes = ['coach', 'therapist'];

  const subscriptions = products.filter((product: Product) =>
    subscriptionTypes.includes(product.productType)
  );
  const recurring = products.filter(
    (product: Product) =>
      product.prices.every(price => price.isRecurring) &&
      !subscriptionTypes.includes(product.productType)
  );
  const oneTime = products.filter((product: Product) =>
    product.prices.every(price => !price.isRecurring)
  );

  return {
    subscriptions,
    recurring,
    oneTime,
  };
};

const sortCurrentProducts = (products: Product[]) => {
  const subscriptionTypes = ['coach', 'therapist'];

  const subscription = products.filter((product: Product) =>
    subscriptionTypes.includes(product.productType)
  )[0];
  const recurring = products.filter(
    (product: Product) =>
      product.price.isRecurring && !subscriptionTypes.includes(product.productType)
  );

  const oneTime = products.filter(
    (product: Product) =>
      !product.price.isRecurring && !subscriptionTypes.includes(product.productType)
  );

  return {
    subscription,
    recurring,
    oneTime,
  } as SortedSubscriptions;
};

export { sortProducts, sortCurrentProducts };
