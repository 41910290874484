import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import axios from 'axios';
import type { Device } from 'src/types/device'

interface DevicesState {
  devices: Device[];
  selectedDevice: Device;
  isModalOpen: boolean;
  isNew: boolean;
}

const initialState: DevicesState = {
  devices: [],
  selectedDevice: undefined,
  isModalOpen: false,
  isNew: false,
};

const slice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    getDevices(state: DevicesState, action: PayloadAction<{ devices: Device[]; }>) {
      const { devices } = action.payload;
      state.devices = devices;
    },
    addDevice(state: DevicesState, action: PayloadAction<{}>) {
      state.isModalOpen = false;
    },
    openModal(state: DevicesState) {
      state.isModalOpen = true;
    },
    closeModal(state: DevicesState) {
      state.isModalOpen = false;
    },
    removeSelectedDevice(state: DevicesState) {
      state.selectedDevice = undefined;
    },
    openAdd(state: DevicesState) {
      state.isNew = true;
    },
    closeAdd(state: DevicesState) {
      state.isNew = false;
    },
  }
});

export const reducer = slice.reducer;

export const openModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const openAdd = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openAdd());
};

export const closeAdd = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeAdd());
};

export const addDevice = (): AppThunk => (dispatch) => {

  dispatch(slice.actions.addDevice({}));
};

export const removeSelectedDevice = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.removeSelectedDevice());
}

export const getDevices = (): AppThunk => async (dispatch) => {

  const userId = JSON.parse(localStorage.getItem('user'))['userId'];
  const response = await axios({
    method: 'get',
    url: `/v2/users/${userId}/devices`,
    headers: { "Authorization": "Bearer " + localStorage.getItem("accessToken")}
  });

  dispatch(slice.actions.getDevices({devices: response.data.ownedDevices}));
}

export default slice;
