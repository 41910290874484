import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import axios from 'axios';
import type { Session } from 'src/types/session'

interface SessionsState {
  sessions: Session[];
  currentSession: Session;
}

const initialState: SessionsState = {
  sessions: [],
  currentSession: undefined,
};

const slice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    getSessions(state: SessionsState, action: PayloadAction<{sessions: Session[]}>) {
      
      const { sessions } = action.payload;
      state.sessions = sessions;
    },
    getCurrentSession(state: SessionsState, action: PayloadAction<{session: Session}>) {

      const { session } = action.payload;

      state.currentSession = session;
    },
  }
});

export const reducer = slice.reducer;

export const getSessions = (): AppThunk => async (dispatch) => {

  const therapistId = JSON.parse(localStorage.getItem('user'))['userId'];
  const timeZone = Intl.DateTimeFormat().resolvedOptions()
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : '';

  const params = timeZone
    ? {
        timeZone: timeZone,
      }
    : {};
  const response = await axios({
    method: 'get',
    url: `v2/therapist/${therapistId}/sessions`,
    params: params
  });

  dispatch(slice.actions.getSessions({sessions: response.data}));
};

export const getCurrentSession = (sessionId: string): AppThunk => async (dispatch) => {
  
  const therapistId = JSON.parse(localStorage.getItem('user'))['userId'];
  const response = await axios({
    method: 'get',
    url: `/v2/therapist/${therapistId}/session/${sessionId}`
  })

  dispatch(slice.actions.getCurrentSession({ session: response.data }))
}

export default slice;
