import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';
import { ProviderClient, SelectedClientList } from 'src/types/client';

interface ClientsState {
  isModalOpen: boolean;
  currentClient: any;
  totalClients: number;
  clientListFetched: boolean;
  clientList: ProviderClient[];
  allClients: ProviderClient[];
  selectedClients: any;
}

const initialState: ClientsState = {
  isModalOpen: false,
  currentClient: undefined,
  totalClients: 0,
  clientList: [],
  allClients: [],
  clientListFetched: false,
  selectedClients: [],
};

const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    getClientList(
      state: ClientsState,
      action: PayloadAction<{ clients: ProviderClient[]; totalClients: number }>
    ) {
      const { clients, totalClients } = action.payload;
      state.totalClients = totalClients;
      state.clientList = clients;
      state.clientListFetched = true;
    },
    getAllClients(state: ClientsState, action: PayloadAction<{ allClients: ProviderClient[] }>) {
      const { allClients } = action.payload;
      state.allClients = allClients;
    },
    createClient(state: ClientsState, action: PayloadAction<boolean>) {
      state.isModalOpen = false;
    },
    getCurrentClient(state: ClientsState, action: PayloadAction<any>) {
      state.currentClient = action.payload;
    },
    setSelectedClients(
      state: ClientsState,
      action: PayloadAction<{ clients: SelectedClientList[] }>
    ) {
      const { clients } = action.payload;
      state.selectedClients = clients;
    },
    openModal(state: ClientsState) {
      state.isModalOpen = true;
    },
    closeModal(state: ClientsState) {
      state.isModalOpen = false;
    },
  },
});

export const reducer = slice.reducer;

export const openModal = (): AppThunk => dispatch => {
  dispatch(slice.actions.openModal());
};

export const closeModal = (): AppThunk => dispatch => {
  dispatch(slice.actions.closeModal());
};

export const setSelectedClients = (data: any): AppThunk => dispatch => {
  dispatch(slice.actions.setSelectedClients({ clients: data }));
};

export const createClient = (data: any): AppThunk => async dispatch => {
  dispatch(slice.actions.createClient(undefined));
};

export const getAllClients = (): AppThunk => async dispatch => {
  const therapistId = JSON.parse(localStorage.getItem('user'))['userId'];

  let allClientsFetched = false;
  const allClientsList = [];
  let page = 0;
  try {
    while (!allClientsFetched) {
      const clientResponse = await axios({
        method: 'get',
        url: `v2/therapist/${therapistId}/clients`,
        params: {
          startFrom: page * 10,
          limit: 10,
        },
      });
      page += 1;
      allClientsList.push(...clientResponse.data.clients);
      if (clientResponse.data.totalClients === allClientsList.length) {
        allClientsFetched = true;
      }
    }
    dispatch(slice.actions.getAllClients({ allClients: allClientsList }));
  } catch (error) {
    console.error('Redux ERROR:', error);
    dispatch(slice.actions.getAllClients({ allClients: [] }));
  }
};

export const getClientList = (
  startFrom?: number,
  searchTerm?: string,
  limit?: number,
  sortKey?: string,
  sortOrder?: string,
  userStatus?: string
): AppThunk => async dispatch => {
  const therapistId = JSON.parse(localStorage.getItem('user'))['userId'];
  try {
    const params = {
      startFrom: startFrom * (limit ? limit : 10) || 0,
      limit: limit || 10,
    };
    if (searchTerm && searchTerm.length > 0) {
      params['searchTerm'] = searchTerm;
    }
    if (sortKey && sortKey.length > 0) {
      params['sortKey'] = sortKey;
    }
    if (sortOrder && sortOrder.length > 0) {
      params['sortOrder'] = sortOrder;
    }
    if (userStatus && userStatus.length > 0) {
      params['userStatus'] = userStatus;
    }
    const response = await axios({
      method: 'get',
      url: `v2/therapist/${therapistId}/clients`,
      params: params,
    });
    dispatch(
      slice.actions.getClientList({
        clients: response.data.clients,
        totalClients: response.data.totalClients,
      })
    );
  } catch (error) {
    dispatch(slice.actions.getClientList({ clients: [], totalClients: 0 }));
  }
};

export const getCurrentClient = (clientId: string): AppThunk => async dispatch => {
  const therapistId = JSON.parse(localStorage.getItem('user'))['userId'];
  const response = await axios({
    method: 'get',
    url: `v2/therapist/${therapistId}/client/${clientId}`,
  });

  dispatch(slice.actions.getCurrentClient(response.data));
};

export default slice;
