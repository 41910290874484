import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { FiMenu as MenuIcon } from 'react-icons/fi';
import { THEMES } from 'src/constants';
import { Theme } from 'src/theme';
import Account from './Account';
import Settings from './Settings';
import Help from './Help';

type TopBarProps = {
  className?: string;
  onMobileNavOpen?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.background.black,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }: TopBarProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const showMenu = useMediaQuery(theme.breakpoints.up(1520)) ? false : true;

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        {showMenu && (
          <div>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </div>
        )}
        {!showMenu && (
          <div>
            <RouterLink to="/">
              <img alt="Logo" src="/static/logo_light.png" height="40px" width="210px" />
            </RouterLink>
          </div>
        )}
        <Box ml={2} flexGrow={1} />

        <Help />

        <Settings />

        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
