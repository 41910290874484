import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import ClientRouter from 'src/components/ClientRouter';
import TherapistRouter from 'src/components/TherapistRouter';
type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
  router?: any;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const CustomRouter = route.router || Fragment;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <CustomRouter>
                    <Layout>
                      {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                    </Layout>
                  </CustomRouter>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      // divergence views
      {
        exact: true,
        path: '/app/clients',
        router: TherapistRouter,
        component: lazy(() => import('src/views/therapist/clients/ClientListView')),
      },
      {
        // exact: true,
        path: '/app/clients/:clientId',
        router: TherapistRouter,
        component: lazy(() => import('src/views/therapist/clients/ClientDetailsView')),
      },
      {
        exact: true,
        router: TherapistRouter,
        path: '/app/client/:clientId/sessions/:sessionId',
        component: lazy(() =>
          import('src/views/therapist/clients/ClientDetailsView/Details/sessions/SessionHistory')
        ),
      },
      {
        exact: true,
        path: '/app/clients/:clientId/sessions',
        router: TherapistRouter,
        component: lazy(() => import('src/views/therapist/clients/ClientDetailsView')),
      },
      {
        exact: true,
        path: '/app/client/:clientId/sessions/:sessionId/assessment',
        router: TherapistRouter,
        component: lazy(() => import('src/components/Assessment/Assessment')),
      },
      {
        exact: true,
        path: '/app/clients/:clientId/questionnaires',
        router: TherapistRouter,
        component: lazy(() => import('src/views/therapist/clients/ClientDetailsView')),
      },
      {
        exact: true,
        path: '/app/devices',
        router: TherapistRouter,
        component: lazy(() => import('src/views/therapist/devices/DeviceListView')),
      },
      {
        exact: true,
        path: '/app/feedbackresponses',
        router: TherapistRouter,
        component: lazy(() =>
          import('src/views/therapist/feedbackResponse/FeedbackResponseListView')
        ),
      },
      {
        exact: true,
        path: '/app/protocols',
        router: TherapistRouter,
        component: lazy(() => import('src/views/therapist/protocols/ProtocolListView')),
      },
      {
        exact: true,
        path: '/app/protocols/:protocolId',
        router: TherapistRouter,
        component: lazy(() => import('src/views/therapist/protocols/ProtocolDetailView')),
      },
      {
        exact: true,
        path: '/app/sessions',
        router: TherapistRouter,
        component: lazy(() => import('src/views/therapist/sessions/SessionListView')),
      },
      {
        exact: true,
        path: '/app/sessions/:sessionId',
        router: TherapistRouter,
        component: lazy(() => import('src/views/therapist/sessions/SessionDetailView')),
      },
      {
        exact: true,
        path: '/app/assessments',
        router: TherapistRouter,
        component: lazy(() => import('src/views/therapist/assessments/AssessmentListView')),
      },
      {
        exact: true,
        path: '/app/eeg-files',
        router: TherapistRouter,
        component: lazy(() => import('src/views/therapist/EEG-Files/EEGListView')),
      },
      {
        exact: true,
        path: '/app/eeg-upload',
        router: TherapistRouter,
        component: lazy(() => import('src/views/therapist/EEG-Upload')),
      },
      {
        exact: true,
        path: '/app/questionnaires',
        router: TherapistRouter,
        component: lazy(() => import('src/views/therapist/questionnaires/QuestionnairesListView')),
      },
      {
        exact: true,
        path: '/app/questionnaire/:assessmentId',
        router: TherapistRouter,
        component: lazy(() =>
          import('src/views/therapist/questionnaires/QuestionnaireDetailsView')
        ),
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView')),
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/clients" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        guard: GuestGuard,
        path: '/',
        component: lazy(() => import('src/views/auth/LoginView')),
      },
      {
        exact: true,
        guard: GuestGuard,
        path: '/login',
        component: lazy(() => import('src/views/auth/LoginView')),
      },
      {
        exact: true,
        // guard: GuestGuard,
        path: '/register',
        component: lazy(() => import('src/views/auth/RegisterView')),
      },
      {
        exact: true,
        // guard: GuestGuard,
        path: '/payment',
        component: lazy(() => import('src/views/auth/Payment')),
      },
      {
        exact: true,
        path: '/pastdue',
        component: lazy(() => import('src/views/auth/SubscriptionPastDue')),
      },
      {
        exact: true,
        path: '/survey',
        component: lazy(() => import('src/views/auth/Survey')),
      },
      {
        exact: true,
        // guard: GuestGuard,
        path: '/purchase-device',
        component: lazy(() => import('src/views/auth/PurchaseDevice')),
      },
      {
        exact: true,
        // guard: GuestGuard,
        path: '/onboard-client',
        // router: ClientRouter,
        component: lazy(() => import('src/views/auth/RegisterView')),
      },
      {
        exact: true,
        guard: GuestGuard,
        path: '/forgot-password',
        component: lazy(() => import('src/views/auth/ForgotPassword')),
      },
      {
        exact: true,
        guard: GuestGuard,
        path: '/verifyForgetPasswordLink',
        component: lazy(() => import('src/views/auth/ResetPassword')),
      },
      {
        exact: true,
        // guard: GuestGuard,
        path: '/welcome',
        component: lazy(() => import('src/views/auth/Welcome')),
      },
      {
        exact: true,
        // guard: GuestGuard,
        path: '/request-verification',
        component: lazy(() => import('src/views/auth/Welcome/Request')),
      },
      {
        exact: true,
        // guard: GuestGuard,
        path: '/verification',
        component: lazy(() => import('src/views/auth/Welcome/Verification')),
      },
      {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/views/errors/NotFoundView')),
      },
      {
        exact: true,
        path: '/aw_snap',
        component: lazy(() => import('src/views/errors/AwSnapView')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
