import axios from 'axios';
import { User } from 'src/types/user';

type GetProviderInfo = {
  providerInfo: User;
  error: boolean;
};

export const getProviderInfo = async (userId: string): Promise<GetProviderInfo> => {
  try {
    const axiosObj = await axios({
      method: 'GET',
      url: `/v2/users/${userId}/info`,
    });

    return {
      providerInfo: axiosObj.data.userFullInfo,
      error: null,
    };
  } catch (e) {
    console.error(e);
    return {
      providerInfo: null,
      error: true,
    };
  }
};


export const updateOnboardStatus = async (userId: string): Promise<boolean> => {
  try {
    await axios({
      method: 'PATCH',
      url: `/v2/users/${userId}/onboarded`,
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};
