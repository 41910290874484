import React, { useState, createContext } from 'react';

interface TimeContextInterface {
  start: () => void;
  stop: () => void;
  setCurrentTime: (arg: number) => void;
  getCurrentTime: () => number;
  getStartStatus: () => boolean;
  setProgressBar: (arg: number) => void;
  setLiveSeconds: (seconds: number) => void;
  progressBar: number;
  startStatus: boolean;
  currentTime: number;
  liveSeconds: number;
}

const TimerContext = createContext<TimeContextInterface>({
  start() {
    throw new Error('You need to wrap your component in <TimeContext>');
  },
  stop() {
    throw new Error('You need to wrap your component in <TimeContext>');
  },
  setCurrentTime() {
    throw new Error('You need to wrap your component in <TimeContext>');
  },
  getCurrentTime() {
    throw new Error('You need to wrap your component in <TimeContext>');
  },
  getStartStatus() {
    throw new Error('You need to wrap your component in <TimeContext>');
  },
  startStatus: true,
  currentTime: 0,
  setProgressBar() {
    throw new Error('You need to wrap your component in <TimeContext>');
  },
  progressBar: 0,
  setLiveSeconds() {
    throw new Error('You need to wrap your component in <TimeContext>');
  },
  liveSeconds: 0,
});

const TimerProvider = ({ children }) => {
  const [startStatus, setStart] = useState(false);
  const [currentTime, setCurrentTimeContext] = useState(0);
  const [progressBar, setProgressBarContext] = useState(0);
  const [liveSeconds, setLiveSecondsContext] = useState(0);

  // === SET AND GET START/STOP ACTIONS: === //
  const start = () => setStart(true);
  const stop = () => setStart(false);
  const getStartStatus = () => startStatus;

  // === SET AND GET TIME ELAPSED ==== //
  // NOTE: You also have direct access to the state `currentTime`.
  const setCurrentTime = (time: number) => setCurrentTimeContext(time);
  const getCurrentTime = () => currentTime;

  // === SET THE PROGRESS BAR ACTIONS === //
  // NOTE: To GET/LISTEN if the progress bar were clicked
  // or dragged use the state variable `progressBar`
  const setProgressBar = (time: number) => setProgressBarContext(time);

  //==== LIVE TIMER =====/

  const setLiveSeconds = (seconds: number) => setLiveSecondsContext(seconds);

  return (
    <TimerContext.Provider
      value={{
        getStartStatus,
        start,
        stop,
        getCurrentTime,
        setCurrentTime,
        startStatus,
        currentTime,
        progressBar,
        setProgressBar,
        setLiveSeconds,
        liveSeconds,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

export default TimerProvider;
export { TimerContext };
