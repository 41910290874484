import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import type { Coupon, Product } from 'src/types/payment'
import { UserQuestionResponse } from 'src/types/survey';
import {cloneDeep} from "lodash";
import { sortProducts } from 'src/utils/payment';

interface ProductsState {
  products: Product[];
  addOns: Product[];
  availableSubscriptions: Product[];
  surveyComplete: boolean;
}

const initialState: ProductsState = {
  products: [],
  addOns: [],
  availableSubscriptions: [],
  surveyComplete: false
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    getCart(state: ProductsState, action: PayloadAction<{ products: any[] }>) {
      const { products } = action.payload;
      state.products = products;
    },
    removeFromCart(state: ProductsState, action: PayloadAction<{ productId: string }>) {
      const { productId } = action.payload;
      const cartWithoutRemovedProduct = cloneDeep(state.products).filter((product: Product) => product.productId !== productId);
      state.products = cartWithoutRemovedProduct;
    },
    addToCart(state: ProductsState, action: PayloadAction<{ products: Product[] }>) {
      const { products } = action.payload;
      const cartCopy = cloneDeep(state.products);
      state.products = [...cartCopy, ...products];
    },
    getRelevantAddOns(state: ProductsState, action: PayloadAction<{ addOns: any[] }>) {
      const { addOns } = action.payload;
      state.addOns = addOns;
    },
    clearAddOns(state:ProductsState) {
      state.addOns = [];
    },
    clearAll(state:ProductsState) {
      state.addOns = [];
      state.availableSubscriptions = [];
      state.products = [];
    },
    getAvailableSubscriptions(state: ProductsState, action: PayloadAction<{ subscriptions: any[] }>) {
      const { subscriptions } = action.payload;
      state.availableSubscriptions = subscriptions;
    },
    switchSubscription(state: ProductsState, action: PayloadAction<{product: Product}>) {
      const { product } = action.payload;
      state.products = [product];
      state.addOns = [];
    },
    updateSurveyStatus(state: ProductsState, action: PayloadAction<{surveyStatus: boolean}>) {
      const { surveyStatus } = action.payload;
      state.surveyComplete = surveyStatus;
    }
  }
});

export const reducer = slice.reducer;

export const getCart = (surveyAnswers: UserQuestionResponse[]): AppThunk => async (dispatch) => {
  try {
    const response = await axios({
      method: 'post',
      url: `/v2/psp/registration/cart`,
      data: surveyAnswers,
    });
    dispatch(slice.actions.getCart({ products: response.data }));
  } catch (e) {
    console.error(e)
  }
};

export const getAvailableSubscriptions = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      url: `/v2/psp/products`,
    });
    if (!response || !response?.data || response.data.length === 0) return;
    const availableProducts = sortProducts(response.data);
    if (!availableProducts || !availableProducts.subscriptions || availableProducts.subscriptions.length === 0) return;
    else dispatch(slice.actions.getAvailableSubscriptions({ subscriptions: availableProducts.subscriptions }))

  } catch (e) {
    console.error(e)
  }
};

export const removeFromCart = (productId: string): AppThunk => async (dispatch) => {
  dispatch(slice.actions.removeFromCart({ productId: productId }));
};

export const addToCart = (products: Product[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.addToCart({ products: products }));
}

export const getRelevantAddOns = (productId: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios({
      method: 'get',
      url: `/v2/psp/product/${productId}/addOns`,
    });
    dispatch(slice.actions.getRelevantAddOns({ addOns: response.data }));
  } catch (e) {
    console.error(e)
  }
}

export const getAddOns = async (productId: string) => {
  try {
    const response = await axios({
      method: 'get',
      url: `/v2/psp/product/${productId}/addOns`,
    });
    if (response && response.data) {
      return {
        error: false,
        data: response.data
      }
    }
    else return {
      error: true
    }
  } catch (e) {
    console.error(e)
    return {
      error: true
    }
  }
}

export const createSubscription = async (priceIds: string[], redeemedCoupons: Coupon[], userId: string, state: string, country: string) => {
  try {
    const response = await axios({
      method: 'post',
      url: `/v2/therapist/${userId}/subscription`,
      data: {
        billing: {
          country: country,
          state: state,
        },
        priceIds: priceIds,
        promoCode: redeemedCoupons && redeemedCoupons.length > 0 ? redeemedCoupons[0].promoCode : ''
      },
    });
    if (response && response.data && response.data.subscription) {
      return {
        error: false
      }
    }
    else {
      return {
        error: true
      }
    }
    
  } catch (e) {
    console.error(e)
    return {
      error: true
    }
  }
};

export const clearAddOns = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearAddOns());
};

export const clearAll = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.clearAll());
};

export const updateSurveyStatus = (): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updateSurveyStatus({surveyStatus: true}));
};

export const switchSubscription = (product: Product) : AppThunk => async (dispatch) => {
  dispatch(slice.actions.switchSubscription({product: product}));
  getRelevantAddOns(product.productId);
};

export default slice;
