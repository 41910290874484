import axios from 'axios';

export const chatClient = async (text, sessionId, endSession) => {
  let requestData = {
    text,
    sessionId,
    endSession,
  };

  try {
    const response = await axios({
      method: 'post',
      url: process.env.REACT_APP_CHAT_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      },
      data: requestData,
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
