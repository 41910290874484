import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'
import type { Questionnaire, Category, Question } from 'src/types/questionnaire'
import axios from 'axios';
import _ from 'lodash';
import { getAllQuestionnaires, getAllCategories, getSpecificQuestionnaire, updateQuestionnaire } from 'src/requests/questionnaires'

interface QuestionnaireState {
  questionnaires: Questionnaire[];
  categories: Category[];
  selectedQuestionnaire: Questionnaire;
  isModalOpen: boolean;
  isNew: boolean;
  isAssignModalOpen: boolean;
}

const initialState: QuestionnaireState = {
  questionnaires: [],
  categories: [],
  selectedQuestionnaire: undefined,
  isModalOpen: false,
  isNew: false,
  isAssignModalOpen: false,
};

const slice = createSlice({
  name: 'questionnaires',
  initialState,
  reducers: {
    getQuestionnaires(state: QuestionnaireState, action: PayloadAction<{ questionnaires: Questionnaire[]; }>) {
      const { questionnaires } = action.payload;
      state.questionnaires = questionnaires;
    },
    openModal(state: QuestionnaireState) {
      state.isModalOpen = true;
    },
    closeModal(state: QuestionnaireState) {
      state.isModalOpen = false;
    },
    getSelectedQuestionnaire(state: QuestionnaireState, action: PayloadAction<Questionnaire>) {
      state.selectedQuestionnaire = action.payload;
    },
    createCategory(state: QuestionnaireState, action: PayloadAction<{}>) {
      state.isNew = false;
    },
    getCategories(state: QuestionnaireState, action: PayloadAction<{categories: Category[]; }>) {
      if (action.payload) {
        state.categories = action.payload.categories;
      } else {
        state.categories = [];
      }
    },
    openAdd(state: QuestionnaireState) {
      state.isNew = true;
    },
    closeAdd(state: QuestionnaireState) {
      state.isNew = false;
    },
    openAssignModal(state: QuestionnaireState) {
      state.isAssignModalOpen = true;
    },
    closeAssignModal(state: QuestionnaireState) {
      state.isAssignModalOpen = false;
    },
    assignQuestionnaire(state: QuestionnaireState, action: PayloadAction<{}>) {
      state.isAssignModalOpen = false;
    },
  }
});

export const reducer = slice.reducer;

export const openModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openModal());
};

export const closeModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeModal());
};

export const openAdd = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openAdd());
};

export const closeAdd = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeAdd());
};

export const openAssignModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openAssignModal());
};

export const closeAssignModal = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeAssignModal());
};

export const refreshQuestionnaire = (data: any): AppThunk => (dispatch) => {
  dispatch(slice.actions.getSelectedQuestionnaire(data));
};

export const getQuestionnaires = (): AppThunk => async (dispatch) => {
  getAllQuestionnaires().then(response => {
    dispatch(slice.actions.getQuestionnaires({questionnaires: response}));
  })
}

export const getCategories = (): AppThunk => async (dispatch) => {
  getAllCategories().then(response => {
    dispatch(slice.actions.getCategories({categories: response}));
  })
}

export const assignQuestionnaire = (data: any): AppThunk => async (dispatch) => {
  const therapistId = JSON.parse(localStorage.getItem('user'))['userId'];

  await axios({
    method: 'post',
    url: `/v2/therapist/${therapistId}/questionnaire/${data.questionnaire_id}/assign`,
    headers: {"Authorization": "Bearer " + localStorage.getItem("accessToken")},
    data: {
      user_id: data.user_id,
      frequency: data.frequency
    }
  });

  dispatch(slice.actions.assignQuestionnaire({}));
}

export const getSelectedQuestionnaire = (questionnaireId: any): AppThunk => async(dispatch) => {
  getSpecificQuestionnaire(questionnaireId).then(response => {
    dispatch(slice.actions.getSelectedQuestionnaire(response));
  })
}

export const createQuestion = (questionnaire: Questionnaire, question: Question): AppThunk => async(dispatch) => {
  const questions = _.cloneDeep(questionnaire.questions);
  questions.push(question)

  const data = {
    name: questionnaire.name,
    description: questionnaire.description,
    categoryId: questionnaire.category.categoryId,
    questions: questions
  }

  updateQuestionnaire(data, questionnaire.questionnaireId).then(response => {
    dispatch(slice.actions.getSelectedQuestionnaire(response.data.questionnaire));
  })
}

export const updateQuestion = (questionnaire: Questionnaire, question: Question): AppThunk => async(dispatch) => {
  const questions = questionnaire.questions.map((element, index)  =>{
    return element.questionId == question.questionId ? question : element
  });

  const data = {
    name: questionnaire.name,
    description: questionnaire.description,
    categoryId: questionnaire.category.categoryId,
    questions: questions
  }

  updateQuestionnaire(data, questionnaire.questionnaireId).then(response => {
    dispatch(slice.actions.getSelectedQuestionnaire(response.data.questionnaire));
  })
}

export const deleteQuestion = (questionnaire: Questionnaire, question_id: string): AppThunk => async(dispatch) => {
  const questions = questionnaire.questions.filter((element)  =>{
    return element.questionId !== question_id;
  }).map((element,index) => {
    return {...element, sequence: index+1}
  });

  const data = {
    name: questionnaire.name,
    description: questionnaire.description,
    categoryId: questionnaire.category.categoryId,
    questions: questions
  }

  updateQuestionnaire(data, questionnaire.questionnaireId).then(response => {
    dispatch(slice.actions.getSelectedQuestionnaire(response.data.questionnaire));
  })
}

export default slice;
