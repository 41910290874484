import type { User } from 'src/types/user';
import { createSlice } from '@reduxjs/toolkit';
// import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store'

interface AuthState {
    isInitialised: boolean;
    isAuthenticated: boolean;
    user: User | null;
}

interface AuthContextValue extends AuthState {
    method: 'JWT',
    login: (email: string, password: string) => Promise<void>;
    logout: () => void;
    register: (email: string, firstName: string, lastName: string, password: string) => Promise<void>;
}

type InitialiseAction = {
    type: 'INITIALISE';
    payload: {
        isAuthenticated: boolean;
        user: User | null;
    };
};

type LoginAction = {
    type: 'LOGIN';
    payload: {
        user: User;
    };
};

type LogoutAction = {
    type: 'LOGOUT';
};

type RegisterAction = {
    type: 'REGISTER';
    payload: {
        user: User;
    };
};

type Action =
    | InitialiseAction
    | LoginAction
    | LogoutAction
    | RegisterAction;

const initialState: AuthState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null
};
   

const slice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        authentication: (state, action) => {
            switch (action.type) {
                case 'INITIALISE': {
                const { isAuthenticated, user } = action.payload;
        
                return {
                    ...state,
                    isAuthenticated,
                    isInitialised: true,
                    user
                };
                }
                case 'LOGIN': {
                const { user } = action.payload;
                console.log(action);
        
                return {
                    ...state,
                    isAuthenticated: true,
                    user
                };
                }
                case 'LOGOUT': {
                return {
                    ...state,
                    isAuthenticated: false,
                    user: null
                };
                }
                case 'REGISTER': {
                const { user } = action.payload;
        
                return {
                    ...state,
                    isAuthenticated: true,
                    user
                };
                }
                default: {
                return { ...state };
                }
            }
        }
    }
});

export const authReducer = (state: AuthState, action: Action): AuthState => {
    switch (action.type) {
      case 'INITIALISE': {
        const { isAuthenticated, user } = action.payload;
  
        return {
          ...state,
          isAuthenticated,
          isInitialised: true,
          user
        };
      }
      case 'LOGIN': {
        const { user } = action.payload;
        console.log("hello");

        state.user = user;
        state.isAuthenticated = true;
        return { ...state }
      }
      case 'LOGOUT': {
        return {
          ...state,
          isAuthenticated: false,
          user: null
        };
      }
      case 'REGISTER': {
        const { user } = action.payload;
  
        return {
          ...state,
          isAuthenticated: true,
          user
        };
      }
      default: {
        return { ...state };
      }
    }
  };

export const authenticate = (): AppThunk => (dispatch) => {
    console.log('hello');
};
export default slice;