import React, { useState } from 'react';
import { makeStyles, Button, TextField } from '@material-ui/core';
import { CHAT_BOT_INFO } from 'src/constants';
import { chatClient } from 'src/requests/chatRequest';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

const HelpButton = () => {
  const windowHeight = window.innerHeight - 394;
  const windowWidth = window.innerWidth;
  const classes = useStyles();
  const localChat = JSON.parse(localStorage.getItem('venusChat'));
  const [showChat, setShowChat] = useState(false);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [chat, setChat] = useState(
    localChat ? localChat : [{ message: CHAT_BOT_INFO.initialText, type: 'bot' }]
  );

  const handleSend = async () => {
    let tempChat = [...chat];
    let tempMessage = { message: input, type: 'user' };

    tempChat.unshift(tempMessage);

    setChat(tempChat);
    setInput('');

    let loadingTimeout = setTimeout(() => setLoading(true), 1000);

    localStorage.setItem('venusChat', JSON.stringify(tempChat));

    chatClient(tempMessage.message, sessionId, false).then(res => {
      let botMessage;

      if (res.error) {
        botMessage = {
          message: CHAT_BOT_INFO.failureText,
          type: 'bot',
          session: res.sessionId,
        };
      } else {
        botMessage = { message: res.text, type: 'bot', session: res.sessionId };
      }

      tempChat.unshift(botMessage);

      if (res.finalResponse) {
        tempChat.unshift({
          message: CHAT_BOT_INFO.question,
          type: 'bot',
          session: res.sessionId,
        });
      }

      localStorage.setItem('venusChat', JSON.stringify(tempChat));

      setSessionId(res.sessionId);
      setLoading(false);
      clearTimeout(loadingTimeout);
      setChat([...tempChat]);
    });
  };

  const handleFinish = () => {
    let tempChat = [...chat];
    let tempMessage = { message: 'Yes', type: 'user' };

    chatClient('end session', sessionId, true).then(() => {
      tempChat.unshift(tempMessage);
      tempChat.unshift({ message: CHAT_BOT_INFO.endSessionText, type: 'bot' });
      localStorage.setItem('venusChat', JSON.stringify(tempChat));

      setSessionId(null);
      setChat(tempChat);
    });
  };

  const handleContinue = () => {
    let tempChat = [...chat];
    let tempMessage = { message: 'No', type: 'user' };

    tempChat.unshift(tempMessage);
    tempChat.unshift({ message: CHAT_BOT_INFO.poorAnswer, type: 'bot' });
    localStorage.setItem('venusChat', JSON.stringify(tempChat));

    setChat(tempChat);
  };

  const handleClear = () => {
    setChat([{ message: CHAT_BOT_INFO.initialText, type: 'bot' }]);
    localStorage.removeItem('venusChat');
  };

  const ChatWindow = () => {
    return (
      <div className={classes.chatContainer}>
        {!loading ? null : <p className={classes.botMessage}>Venus is typing...</p>}

        {chat.map(item => {
          if (item.type === 'user') {
            return (
              <p style={{ right: fullScreen ? -195 : -100 }} className={classes.userMessage}>
                {item.message}
              </p>
            );
          } else if (item.message === CHAT_BOT_INFO.question) {
            return (
              <div className={classes.botMessage}>
                <p>{item.message}</p>

                <div className={classes.finalResponseContainer}>
                  <Button
                    disabled={item.session === sessionId ? false : true}
                    className={item.session !== sessionId ? null : classes.button}
                    onClick={handleFinish}
                  >
                    Yes
                  </Button>

                  <Button
                    disabled={item.session === sessionId ? false : true}
                    className={item.session !== sessionId ? null : classes.button}
                    onClick={handleContinue}
                  >
                    No
                  </Button>
                </div>
              </div>
            );
          } else {
            return <p className={classes.botMessage}>{item.message}</p>;
          }
        })}
      </div>
    );
  };

  return (
    <div
      style={{
        height: showChat ? (fullScreen ? '100%' : windowHeight > 600 ? 600 : '80%') : 70,
        width: showChat ? (fullScreen ? '100%' : windowWidth > 600 ? 600 : '90%') : 70,
        position: 'absolute',
        bottom: fullScreen ? 0 : 10,
        right: fullScreen ? 0 : 10,
        overflow: 'hidden',
        border: '2px solid #773cfe',
        transition: '0.8s all',
        borderRadius: showChat ? 0 : 100,
        display: showChat ? null : 'flex',
        justifyContent: showChat ? null : 'center',
        alignItems: showChat ? null : 'center',
        zIndex: 1301,
      }}
    >
      {showChat ? (
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <div className={classes.headerLeftContainer} onClick={() => setShowChat(!showChat)}>
              <div className={classes.iconContainer}>
                <img src="/static/images/venus.jpg" alt="venus" className={classes.icon} />
              </div>

              <p>Venus</p>
            </div>

            <div className={classes.headerRightContainer}>
              {fullScreen ? (
                <FullscreenExitIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => setFullScreen(false)}
                />
              ) : (
                <FullscreenIcon style={{ cursor: 'pointer' }} onClick={() => setFullScreen(true)} />
              )}

              <Button
                disabled={loading ? true : false}
                className={classes.clearButton}
                onClick={handleClear}
              >
                Clear Chat
              </Button>
            </div>
          </div>

          <ChatWindow />

          <div className={classes.inputContainer}>
            <TextField
              className={classes.input}
              multiline
              onChange={e => setInput(e.target.value)}
              value={input}
              onKeyDown={e => (e.key === 'Enter' && input !== '' && !loading ? handleSend() : null)}
              rows={4}
              InputProps={{ disableUnderline: true }}
            />

            <Button
              className={input === '' || loading ? null : classes.button}
              disabled={input === '' ? true : false}
              onClick={loading ? null : handleSend}
            >
              send
            </Button>
          </div>
        </div>
      ) : (
        <div className={classes.displayIcon} onClick={() => setShowChat(!showChat)} />
      )}
    </div>
  );
};

export default HelpButton;

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    width: '100%',
    background: '#ECECEC',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'Roboto',
    color: '#fff',
  },
  headerContainer: {
    background: '#773cfe',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  headerLeftContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  headerRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    height: '100%',
    justifyContent: 'space-between',
  },
  iconContainer: {
    height: 70,
    width: 70,
    overflow: 'hidden',
    border: '2px solid white',
    borderRadius: 150,
    marginRight: 10,
  },
  finalResponseContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 10,
  },
  icon: {
    height: '130%',
    width: '100%',
  },
  inputContainer: {
    display: 'flex',
    border: '1px solid #c4c4c4',
    margin: 4,
    borderRadius: 4,
  },
  input: {
    width: '100%',
    border: 'none',
    outline: 'none',
    height: 'calc(100% - 4px)',
    margin: 2,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    padding: '0 4px',
    fontFamily: 'Roboto',
  },
  clearButton: {
    background: '#fff',
    color: '#773cfe',
    '&:hover': {
      background: '#fff',
    },
  },
  button: {
    background: '#773cfe',
    color: '#fff',
    '&:hover': {
      background: '#773cfe',
    },
  },
  displayIcon: {
    backgroundImage: 'url(/static/images/venus.jpg)',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    height: 70,
    width: 70,
    backgroundSize: 'cover',
    borderRadius: 100,
    cursor: 'pointer',
  },
  chatContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
    overflowY: 'scroll',
  },
  userMessage: {
    margin: 10,
    padding: 10,
    borderRadius: 4,
    lineHeight: 1.3,
    width: '80%',
    background: '#773cfe',
    position: 'relative',
  },
  botMessage: {
    margin: 10,
    background: 'white',
    padding: 10,
    borderRadius: 4,
    textAlign: 'left',
    lineHeight: 1.3,
    width: '80%',
    color: 'black',
  },
}));
