import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

type AuthGuardProps = {
  children?: ReactNode;
};

const AuthGuard = ({ children }: AuthGuardProps) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/" />;
  }

  switch (user.subscriptionStatus) {
    case 'active':
    case 'trialing':
      return <>{children}</>;
    case 'expired':
    case 'canceled':
      return <Redirect to={{ pathname: '/survey' }} />;
    case 'past_due':
      return <Redirect to={{ pathname: '/pastdue' }} />;
    default:
      return <Redirect to="/aw_snap" />;
  }
};

export default AuthGuard;
