import React, { useEffect } from 'react';
import { ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import {
  FiUsers as UsersIcon,
  FiActivity as ProtocolIcon,
  FiCpu as DeviceIcon,
  FiSettings as SettingsIcon,
  FiSliders as FeedbackResponseIcon,
  FiClipboard as QuestionnaireIcon,
  FiBarChart2 as AssessmentIcon,
  FiLayers as SessionIcon,
} from 'react-icons/fi';

import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import CommunityHelpCard from 'src/components/CommunityHelpCard';
import { APP_VERSION } from 'src/constants';

type RenderNavItemsProps = {
  items: Item[];
  pathname: string;
  depth?: number;
};

type ReduceChildRoutesProps = {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
};

type NavBarProps = {
  onMobileClose: () => void;
  openMobile: boolean;
};

type Item = {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
};

type Section = {
  items: Item[];
};

const coachSections: any[] = [
  {
    title: 'Clients',
    icon: UsersIcon,
    href: '/app/clients',
  },
  {
    title: 'Questionnaires',
    icon: QuestionnaireIcon,
    href: '/app/questionnaires',
  },
  {
    title: 'Devices',
    icon: DeviceIcon,
    href: '/app/devices',
  },
  {
    title: 'Feedback Responses',
    icon: FeedbackResponseIcon,
    href: '/app/feedbackresponses',
  },
  {
    title: 'Protocols',
    icon: ProtocolIcon,
    href: '/app/protocols',
  },
  {
    title: 'Sessions',
    icon: SessionIcon,
    href: '/app/sessions',
  },
  {
    title: 'Settings',
    icon: SettingsIcon,
    href: '/app/account',
  },
];

const therapistSections: any[] = [
  {
    title: 'Clients',
    icon: UsersIcon,
    href: '/app/clients',
  },
  {
    title: 'Questionnaires',
    icon: QuestionnaireIcon,
    href: '/app/questionnaires',
  },
  {
    title: 'Devices',
    icon: DeviceIcon,
    href: '/app/devices',
  },
  {
    title: 'Feedback Responses',
    icon: FeedbackResponseIcon,
    href: '/app/feedbackresponses',
  },
  {
    title: 'Protocols',
    icon: ProtocolIcon,
    href: '/app/protocols',
  },
  {
    title: 'Assessments',
    icon: AssessmentIcon,
    href: '/app/assessments',
  },
  {
    title: 'Sessions',
    icon: SessionIcon,
    href: '/app/sessions',
  },
  {
    title: 'Settings',
    icon: SettingsIcon,
    href: '/app/account',
  },
];

function renderNavItems({ items, pathname, depth = 0 }: RenderNavItemsProps): ReactNode {
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }: ReduceChildRoutesProps): ReactNode[] {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        className={'step' + item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }: NavBarProps) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const currentSection =
    user?.userType && user.userType === 'T' ? therapistSections : coachSections;

  const theme = useTheme();
  const showMenu = useMediaQuery(theme.breakpoints.up(1520));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {!showMenu && (
          <div>
            <Box p={2} display="flex" justifyContent="center">
              <RouterLink to="/">
                <Logo />
              </RouterLink>
            </Box>
          </div>
        )}
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatarUrl} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.firstName + ' ' + user.lastName}
            </Link>
            <Typography variant="body2" color="textSecondary">
              User Type:&nbsp;
              {
                {
                  T: 'Therapist',
                  CH: 'Coach',
                  C: 'Client',
                }[user.userType]
              }
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          <List>
            {renderNavItems({
              items: currentSection,
              pathname: location.pathname,
            })}
          </List>
        </Box>
      </PerfectScrollbar>
      <CommunityHelpCard />
      <Box p={2}>
        <Typography style={{ fontSize: '11px', color: '#546E7A' }}>
          v{APP_VERSION}
          <br />
          © Copyright 2023
          <br />
          Divergence Neuro Technologies Inc.
        </Typography>
      </Box>
    </Box>
  );

  return showMenu || openMobile ? (
    <>
      <div>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </div>
      <div>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </div>
    </>
  ) : null;
};

export default NavBar;
