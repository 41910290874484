import React, { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

type GuestGuardProps = {
  children?: ReactNode;
};

const GuestGuard = ({ children }: GuestGuardProps) => {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated && user.verified) {
    if (user.userType === 'C') {
      return <Redirect to="/welcome" />;
    } else {
      if (!user.subscriptionStatus) {
        return (
          <Redirect
            to={{
              pathname: '/register',
              state: { case: 'payment', email: user.email },
            }}
          />
        );
      } else {
        return <Redirect to="/app/clients" />;
      }
    }
  } else if (isAuthenticated && !user.verified) {
    return (
      <Redirect
        to={{
          pathname: user.userType === 'T' ? '/register' : '/onboard-client',
          state: { case: 'verify', email: user.email },
        }}
      />
    );
  }

  return <>{children}</>;
};

export default GuestGuard;
