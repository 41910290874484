import { Box, Button, Typography, Link } from '@material-ui/core';
import React from 'react';

const CommunityHelpCard = () => (
  <Box
    p={2}
    display="flex"
    alignItems="center"
    flexDirection="column"
    borderRadius="5px"
    style={{ backgroundColor: '#F8F1FF', margin: '10px 5%' }}
    width="90%"
    className="stepResources"
  >
    <Typography color="secondary" style={{ fontSize: '16px', fontWeight: 650 }}>
      Need Help?
    </Typography>
    <Box paddingLeft="12px" paddingRight="12px" paddingTop="4px">
      <Typography align="center" style={{ fontSize: '14px', fontWeight: 400, color: '#263238' }}>
        Get answers to your questions and contact us
      </Typography>
    </Box>
    <Box padding="12px">
      <img src="/static/images/community-help.svg" alt="community-help" />
    </Box>
    <Box display="flex" flexDirection="column" gridGap="10px">
      <Link href="https://community.divergenceneuro.com/" target="_blank" rel="noopener noreferrer">
        <Button color="secondary" variant="contained" fullWidth>
          Visit the Community
        </Button>
      </Link>
    </Box>
  </Box>
);

export default CommunityHelpCard;
