import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import type { FC, ReactNode } from 'react';
import { jwtDecode } from "jwt-decode";
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'axios';
import { getConnectedApps } from 'src/requests/connectedApps';
import { getProviderInfo, updateOnboardStatus } from 'src/requests/provider';
import { useSnackbar } from 'notistack';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT',
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, firstName: string, lastName: string, password: string, userType: string, extraData: string) => Promise<void>;
  update:(user: User)=>void,
  updateOnboardingStatus: () => void;
  getUserInfo: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    isAuthenticated: boolean;
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type UpdateAction = {
  type: 'UPDATE';
  payload: {
    user: User;
  };
};

type Action =
  | InitialiseAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | UpdateAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string) => {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const setUserInfo = async (user: string): Promise<void> => {

    const userToLocalStorage = JSON.parse(user);
    delete userToLocalStorage.userType;
    delete userToLocalStorage.productType;
    delete userToLocalStorage.subscriptionStatus;
    delete userToLocalStorage.verified;
    delete userToLocalStorage.currentActiveClients;
    delete userToLocalStorage.maxActiveClients;
    delete userToLocalStorage.apps;
    delete userToLocalStorage.appsNames;

    // The following key (planLevel) will be removed from our RDS.
    delete userToLocalStorage.planLevel;

    if(userToLocalStorage.subscriptions) delete userToLocalStorage.subscriptions;

    localStorage.setItem('user', JSON.stringify(userToLocalStorage));
    localStorage.setItem('userId', JSON.stringify(userToLocalStorage.userId));

};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isInitialised: true,
        isAuthenticated,
        user,
      };
    }
    case 'UPDATE':{
      const { user } = action.payload;

      return {
        ...state,
        isInitialised: true,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => { },
  register: () => Promise.resolve(),
  update: () => { throw new Error('You need to wrap your component in <AuthProvider>'); },
  updateOnboardingStatus: () => { throw new Error('You need to wrap your component in <AuthProvider>'); },
  getUserInfo: () => { throw new Error('You need to wrap your component in <AuthProvider>'); },
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { enqueueSnackbar } = useSnackbar();

  const login = async (email: string, password: string) => {

    const response = await axios({
      method: 'post',
      url: '/signin',
      data: {
        password: password,
        email: email,
      }
    });

    let error = null;

    switch (response.data.status) {
      case 202:
        let accessToken = response.data.accessToken;
        let user = response.data.response;
        setSession(accessToken);
        setUserInfo(JSON.stringify(user));
        dispatch({
          type: 'LOGIN',
          payload: {
            isAuthenticated: true,
            user,
          }
        });
        return error;
      case 200:
        try {
          if (response?.data?.response?.userType === "C") return {
              reasonCode: "unacceptable_client_login",
              user: response.data.response,
              accessToken: response.data.accessToken
          };
          
          setSession(response.data.accessToken);
          const axiosConnectedApp = await getConnectedApps(response.data.response.userId);
          const {apps, appsNames, error} = axiosConnectedApp;

          if(error) {
            throw new Error("Could not get connected apps.");
          }
          const userWithApps = {...response.data.response, apps, appsNames}

          await setUserInfo(JSON.stringify(userWithApps));
          dispatch({
            type: 'LOGIN',
            payload: {
              isAuthenticated: true,
              user: userWithApps,
            }
          });
          return error;
        } catch(e) {

          error = {
            message: 'Something went wrong during the authentication.'
          }
          return error;
        }
      case 401:
        dispatch({
          type: 'LOGIN',
          payload: {
            isAuthenticated: false,
            user: null,
          }
        });
        error = {
          message: 'Incorrect password.'
        }
        return error;
      case 404:
        dispatch({
          type: 'LOGIN',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
        error = {
          message: 'User does not exist.'
        }
        return error;
      default:
        error = {
          message: 'Something went wrong during the authentication.'
        }
        return error;
    }
  };

  const update = async (user: User) => {
      setUserInfo(JSON.stringify(user));
      dispatch({
        type: 'UPDATE',
        payload: {
          user,
        }
      });
  };

  const getUserInfo = async () => {
     const [{providerInfo, error: providerInfoFail}, {apps, appsNames, error} ] = await Promise.all([
      getProviderInfo(state.user.userId),
      getConnectedApps(state.user.userId)
    ]);

    if(providerInfoFail) {
        enqueueSnackbar("Could not get the user info.", {
          variant: 'error',
        });
      return;
    }

    if(error) {
      enqueueSnackbar("Could not get connected apps.", {
        variant: 'error',
      });
      return;
    }
    const userWithApps: User = {...providerInfo, apps, appsNames}

    await setUserInfo(JSON.stringify(userWithApps));
    dispatch({
      type: 'UPDATE',
      payload: {
        user: userWithApps
      }
    });
  }

  const logout = () => {
    // Remove all keys and values from local storage:
    let onboarding = localStorage.getItem('onboarding');
    localStorage.clear();
    localStorage.setItem('onboarding', onboarding);
    // Remove axios headers (bearer token)
    delete axios.defaults.headers.common.Authorization;
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (signUpData: any, userType: string) => {

    try {
    const url = `/register/${userType}`;

    const response = await axios.post(url, signUpData);

    const accessToken = response.data.accessToken;
    const user = response.data.response;
    if (response.data.assignAssessments) {
      user.assessments = response.data.assignAssessments;
    }
    setSession(accessToken);
    setUserInfo(JSON.stringify(user));
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
    return user;
  } catch (e) {
    if (e.response && e.response.data.message.toLowerCase().includes("jwt expired")) {
      enqueueSnackbar(
        'Your account cannot be verified as the invitation link is over 24 hours old. Please contact your care provider for an updated link',
        { variant: 'error' }
      );
    } else {
      console.error(e);
      enqueueSnackbar('Sorry, something went wrong during the registration process. Please try again later.', {
        variant: 'error',
      });
    }
  }
  };

  const updateOnboardingStatus = async () => {
    const status = await updateOnboardStatus(state.user.userId);

    if(!status){
      enqueueSnackbar('Unable to complete Onboarding guide.', {
        variant: 'error',
      });
    }else{
      enqueueSnackbar('Congrats, you have completed the onboarding guide!', {
        variant: 'success',
      });
      update({...state.user, onboarded: status})
    }
  }

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const userId = JSON.parse(window.localStorage.getItem('userId'));

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const errorMessage = null;

          // CALL USER OBJ API HERE
          const [{providerInfo, error: providerInfoFail}, {apps, appsNames, error} ] = await Promise.all([
            getProviderInfo(userId),
            getConnectedApps(userId)
          ]);

          if(providerInfoFail) {
            throw new Error("Could not get the user info.");
          }

          if(error) {
            throw new Error("Could not get connected apps.");
          }
          const userWithApps: User = {...providerInfo, apps, appsNames}

          await setUserInfo(JSON.stringify(userWithApps));

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: userWithApps,
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        update,
        updateOnboardingStatus,
        getUserInfo
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
