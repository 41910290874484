import React, { ReactNode, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Theme } from 'src/theme';
import NavBar from './NavBar';
import TopBar from './TopBar';
import HelpButton from 'src/components/Help/HelpButton';
import NotificationBanner from './TopBar/NotificationBanner';
import useAuth from 'src/hooks/useAuth';
import { getNotifications, readNotifications } from 'src/requests/notifications';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const { user } = useAuth();
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    getNotifications(user.userId).then(res => {
      if (res) {
        setNotifications(res);

        res.map(item => {
          readNotifications(user.userId, item.notificationId);
        });
      }
    });
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.noPrint}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />

        {!notifications
          ? null
          : notifications.map(data => {
              return <NotificationBanner data={data} id={user.userId} />;
            })}

        <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
      </div>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content} id="contentWrapper">
            <div className={classes.noPrint}>
              <HelpButton />
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    width: '100%',
    '@media print': {
      backgroundColor: 'white',
    },
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    paddingTop: 64,
    [theme.breakpoints.up(1520)]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    '@media print': {
      overflow: 'initial',
    },
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
}));
